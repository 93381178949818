import React, {useState} from 'react';
import {
  List,
  ListItem,
  TableContainer,
  TableCell,
  Table,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';

const useStyles = makeStyles((theme) => ({
  statusHeader: {
    textAlign: 'right !important'
  },
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  subtitle: {
    marginTop: '16px !important',
    fontWeight: 'bold !important',
  },
  healthItem: {
    backgroundColor: 'white',
    padding: '16px !important',
    marginBottom: '8px !important',
    fontWeight: 'bold !important',
  },
  invHead: {
    backgroundColor: '#E1E9F0',
  },
  invHeadLabel: {
    fontWeight: 'bold !important',
  },
  invRow: {
    backgroundColor: 'white',
  },
  selected: {
    border: '1px solid red'
  },
  cap: {
    textTransform: 'capitalize'
  },
  invIcon: {
    paddingRight: '16px !important',
    textAlign: 'right !important'
  }
}));

const HealthInvestments = ({ health }) => {
  const classes = useStyles();
  const [selection, setSelection] = useState(null);
  const healthItems = selection === null ? null : health[selection].items;
  const healthLabel = selection === null ? null : health[selection].label;
  const healthIcons = {
    ok: <CheckCircleRoundedIcon className={classes.success} />,
    inRepair: <CancelRoundedIcon className={classes.warning} />,
    alarm: <ErrorRoundedIcon className={classes.error} />,
  };
  const investmentIcons = {
    unassigned: <ErrorRoundedIcon className={classes.error} />,
    paused: <PauseCircleOutlineOutlinedIcon />,
    inProgress: <ChangeCircleOutlinedIcon />,
    finished: <CheckCircleRoundedIcon className={classes.success} />,
  };
  return (
    <div className={classes.wrapper}>
      <Typography variant='h6' className={classes.subtitle}>
        HEALTH
      </Typography>
      <List className={classes.healthList}>
        {health.map((category, i) => (
          <ListItem 
            key={category.label}
            className={clsx(classes.cap, classes.healthItem,  i === selection && classes.selected)} 
            secondaryAction={<>{healthIcons[category.alert ? 'inRepair' : 'ok']}<KeyboardArrowDownIcon /></>}
            onClick={() => setSelection(i)}
          >
            {category.label}
          </ListItem>
        ))}
      </List>
      {healthItems && 
        <>
          <Typography variant='h6' className={classes.subtitle}>
            ALERTS
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={classes.invHead}>
                <TableRow>
                  <TableCell className={clsx(classes.cap, classes.invHeadLabel)}>{healthLabel}</TableCell>
                  <TableCell className={clsx(classes.statusHeader, classes.invHeadLabel)}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {healthItems.map((healthItem) => (
                  <TableRow className={classes.invRow} key={healthItem.label}>
                    <TableCell className={classes.cap}>{healthItem.label}</TableCell>
                    {healthItem.type === 'alert' ? (
                      <TableCell className={classes.invIcon}>{investmentIcons[healthItem.alert ? 'unassigned' : 'finished']}</TableCell>
                    ) : (
                      <TableCell className={classes.invIcon}>{healthItem.value}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    </div>
  );
};

export default HealthInvestments;
