import React, {useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from '@mui/material';
import BuildingCard from 'components/BuildingCard';
import { loadBuilding } from 'store/Building/building.actions';
import SmartSiteScaffold from 'components/SmartSiteScaffold';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
}));

const mockBuildings = [
  {
    name: '120 Torresdale Ave',
    status: 'heat',
    health: 'ok',
    alerts: 0,
    savings: '$0K',
    lastUpdated: '2 minutes ago',
  },
  {
    name: '300 Antibes Dr',
    status: 'heat',
    health: 'ok',
    alerts: 0,
    savings: '$0K',
    lastUpdated: '1 hour ago',
    disabled: true,
  },
  {
    name: '1101 Bay St',
    status: 'heat',
    health: 'ok',
    alerts: 0,
    savings: '$0K',
    lastUpdated: 'a moment ago',
    disabled: true
  },
];

const HomePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.building.loading);
  const currentBuilding = useSelector((store) => store.building.currentBuilding);
  if (currentBuilding) {
    mockBuildings[0]['alerts'] = currentBuilding.alertCount;
    mockBuildings[0]['savings'] = `$${Math.round(currentBuilding.totalSavingsYTD / 1000)}K`;
    mockBuildings[0]['health'] = currentBuilding.alertCount > 0 ? 'warn' : 'ok';
  }
  useEffect(() => {
    dispatch(loadBuilding(null));
  }, [dispatch]);
  return ((loading || !currentBuilding) ? (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.spinner} />
        </div>
    ) : (
      <SmartSiteScaffold 
        defaultSelection={0} 
        totalSavings={currentBuilding.totalSavingsYTDF}
        totalSavingsMonthly={currentBuilding.totalSavingsMonthlyF}
        totalSavingsDaily={currentBuilding.totalSavingsDailyF}
        totalSavingsWeekly={currentBuilding.totalSavingsWeeklyF}
      >
        <div className={classes.grid}>
          {mockBuildings.map((building) => (
            <BuildingCard key={building.name} {...building} />
          ))}
        </div>
      </SmartSiteScaffold>
    )
  );
};

export default HomePage;
