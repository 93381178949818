export const getUtilityData = async (id) => {
    try {
        const apiKey = '497ea80a7f5149c3a6b2348ae956cd40acd5000bd427410c9a7104f81833166ca74266d26bbe4d5c8dbfccd347ddf8d1';
        const url = 'https://report.smartsiteportal.com/api/v1/';
        const endpoint = 'Building/Utility/86';
        const headers = {
            'API-Token': apiKey
        }
        const result = await fetch(`${url}${endpoint}`, {
            headers,
            method: 'GET'
        });
        const data = await result.json();
        const utilityData = {
            totalSavings: 0
        }
        data.forEach(utility => {
            const utilityName = utility['utilityName'];
            const units = utility['utilityUnits'];
            const forecastedData = utility['consuptionSummaries'].map(x => ({
                value: x['forcastedCost'] || 0,
                label: x['month']
            }))
            const actualData = utility['consuptionSummaries'].map(x => ({
                value: x['actualCost'] || 0,
                label: x['month']
            }))
            const savingsData = utility['consuptionSummaries'].map(x => ({
                value: x['savings'] || 0,
                label: x['month']
            }))
            let acc = 0;
            const accumSavingsData = utility['consuptionSummaries'].map(x => {
                acc += x['savings'] || 0;
                return {
                    value: acc,
                    label: x['month']
                }
            });
            utilityData['totalSavings'] += acc;
            utilityData[utilityName] = {
                name: utilityName,
                units,
                forecasted: forecastedData,
                actual: actualData,
                savings: savingsData,
                accSavings: accumSavingsData
            }
        })
        return utilityData
    } catch (e) {
        console.error(e);
        return {}
    }
}

export const getPoints = async (id, point, currentValue) => {
    try {
        const url = 'https://api.smartsiteportal.com'
        const apiKey = 'UOCCFoxh05UdiFbov83b6LKu2H1sIamb';
        const headers = {
            'API-Token': apiKey,
        };
        const endpoint = `${url}/v1/point/${id}${currentValue ? '/CurrentValue' : ''}`;
        const result = await fetch(endpoint, {
            headers,
            method: 'GET'
        });
        console.log(result)
        return {point, value: await result.json()};
    } catch (e) { 
        console.error(e)
        return null;
    }
}